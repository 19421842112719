.dispute{
    background-color: white;
    margin: 1rem;
    padding: 1rem;

    .dispute-title{
        font-size: 1rem;
        font-weight: 600;
        color:#4E4A4A;
    }
  
}