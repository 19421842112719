.account-credit{
    background-color: white;
    margin: 1rem;
    padding: 1rem;

    .account-title{
        font-size: 1rem;
        font-weight: 600;
        color:#4E4A4A;
    }
   
}